import data from './production.json';
export const environment = {
  production: true,
  version: data.version,
  configuration: 'prod',
  sentry_dsn: 'https://6375983a0eb64fd7bf018d0448d08272@o460562.ingest.sentry.io/6458629',
  deploy_url: data.deploy_url,
  recaptcha: '6LdcCf4kAAAAANc2h846MnuJXQr090BiV_yOYayq',
  geocoderKey: 'afpbf2abeaf290cf978a7bb5360dc180ffec9db0b7ec94ad2440a37a5ec955150a8',
  googleAnalyticsId: 'G-WJV011HC50',
  hotJarId: '5031857',
};
