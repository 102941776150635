import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Reservation } from '../interfaces/shopping-cart.interface';
import { User, SessionRequest } from '../interfaces/user.interface';
import { ResetPasswordRequest, EmailValidation, LoginRequest, AuthenticationContext } from '../interfaces/auth.interface';
import { OrderRequest } from '../interfaces/order.interface';
import { ShopConfigService } from './shop-config.service';
import { Shop } from '../interfaces/shop.interface';

@Injectable({
  providedIn: 'root',
})
export class ShopApiService {
  constructor(
    private http: HttpClient,
    private shopConfigService: ShopConfigService
  ) {}

  getRequestOptions() {
    return {};
  }

  getShop = (name: string) => {
    let params = new HttpParams();
    const source = this.shopConfigService.source;
    const campaign = this.shopConfigService.campaign;
    if (source) {
      params = params.append('source', source);
    }
    if (campaign) {
      params = params.append('campaign', campaign);
    }

    return this.http.get<Shop>(`api/shop/${name}`, { params: params });
  };

  getShoppingCart = (shoppingCartUuid: string) => this.http.get(`v1.0/shopping-carts/${shoppingCartUuid}`, this.getRequestOptions());

  deleteShoppingCart = (shoppingCartUuid: string) => this.http.delete(`v1.0/shopping-carts/${shoppingCartUuid}`, this.getRequestOptions());

  postReservation = (shopUuid: string, reservation: Reservation) => {
    let params = new HttpParams();
    const source = this.shopConfigService.source;
    const campaign = this.shopConfigService.campaign;
    if (source) {
      params = params.append('source', source);
    }

    if (campaign) {
      params = params.append('campaign', campaign);
    }

    return this.http.post(`v1.0/shops/${shopUuid}/reservations/${reservation.product_uuid}`, reservation, { params: params });
  };

  deleteReservation = (shoppingCartUuid: string, reservationUuid: string) =>
    this.http.delete(`v1.0/shopping-carts/${shoppingCartUuid}/reservations/${reservationUuid}`, this.getRequestOptions());

  getPaymentsMethod = (shoppingCartUuid: string) =>
    this.http.get(`v1.0/shopping-carts/${shoppingCartUuid}/payment-methods`, this.getRequestOptions());

  getPaymentMethod = (shoppingCartUuid: string, paymentMethodName: string) =>
    this.http.get(`v1.0/shopping-carts/${shoppingCartUuid}?payment_method_name=${paymentMethodName}`, this.getRequestOptions());

  putManualDetails = (shoppingCartUuid: string, user: User) =>
    this.http.put(`v1.0/shopping-carts/${shoppingCartUuid}/buyer`, user, this.getRequestOptions());

  postResetPassword = (resetPasswordRequest: ResetPasswordRequest) =>
    this.http.post(`rest-api/v1/users/reset_password`, resetPasswordRequest, this.getRequestOptions());

  postOrder = (orderRequest: OrderRequest) => this.http.post(`v1.0/orders`, orderRequest, this.getRequestOptions());

  getOrder = (orderUuid: string) => this.http.get(`v1.0/orders/${orderUuid}`, this.getRequestOptions());

  postEmailValidation = (email: EmailValidation) => this.http.post(`api/emails/validations`, email, this.getRequestOptions());

  postSession = (sessionRequest: SessionRequest) => this.http.post(`v1.0/shop-sessions`, sessionRequest, this.getRequestOptions());

  getShopEvents = (params?) => this.http.get(`api/web/shops`, { params: params || {} });

  bindOrder = (context: AuthenticationContext) => this.http.post(`rest-api/v1/shop/bind_order`, { context });
}
